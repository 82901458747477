import { graphql } from "gatsby";
import getAllPageComponent from "./getAllPageComponent";

export const AllSaleItemFragment = graphql`
    fragment AllSaleItem on MarkdownRemarkEdge {
        node {
            excerpt(pruneLength: 400)
            id
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                description
                showNew
            }
            ...RemoteBannerImageSmall
        }
    }`;

export const pageQuery = graphql`
    query AllSalePageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "all-sale/all-sale-page" } }) {
            html
            frontmatter {
                title
                heading
                eventText
            }
            fields {
                slug
            }
            ...RemoteBannerImage
        }
        items: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {frontmatter: { allSaleIndex: { eq: true } } }
        ) {
            edges {
		            ...AllSaleItem
            }
        }
        highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 2) {
            edges {
                ...EventHighlight
            }
        }
    }`;

export default getAllPageComponent();
