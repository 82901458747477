import getGenericPage from "../generic/genericPage";
import { trackEvent, transformEdgesNodes } from "../../components/utils";
import styled from "styled-components";
import NavLink from "../../components/Link/NavLink";
import EventHighlightSection from "../../components/EventHighlightSection/EventHighlightSection";
import FeaturedEvents, { StyledFeaturedEvent } from "../../components/FeaturedEvents/FeaturedEvents";
import PageSection from "../../components/PageSection/PageSection";
import FeaturedContent from "../../components/FeaturedContent/FeaturedContent";
import React from "react";

const LinkToAllContainer = styled.p`
	justify-content: center;
	display: flex;
	text-decoration: underline;
`;

const getProductsTitle = (page) => {
	return page.filterTag ?
		<>
			המוצרים שלנו לפיתוח <strong style={{ fontSize: "40px" }}>{page.filterTag}</strong>
			<LinkToAllContainer>
				<NavLink to="/allsale/" size="large">לחצו כאן לכל המוצרים</NavLink>
			</LinkToAllContainer>
		</> : "המוצרים שלנו";
};

const trackProductClick = (item) => {
	trackEvent("all sale page", "Products", `click on product: ${item.title}`, item.slug);
};

const PageContent = ({ page, highlightedEvents }) => {
	return <>
		{highlightedEvents &&
		<EventHighlightSection bottomBorder width="80%">
			<FeaturedEvents
				title={page.eventText}
				items={highlightedEvents}
				ItemComponent={StyledFeaturedEvent}
				page={page.slug}
			/>
		</EventHighlightSection>}
		<PageSection>
			{page.items && page.items.length ?
				<FeaturedContent
					title={getProductsTitle(page)}
					items={page.items}
					containerJustif y="center"
					ItemComponent={StyledFeaturedEvent}
					onItemClick={trackProductClick}
					hideItemDescription
				/> : <p>לא נמצאו מוצרים תואמים לתחום {page.filterTag}</p> }
		</PageSection>
	</>;
};

const additionalDataTransformer = (data, pageContext) => {
	return {
		items: transformEdgesNodes(data.items),
		...pageContext
	};
};

const getAllPageComponent = (options = {}) => getGenericPage(PageContent,
	{
		childrenInsideMainSection: true,
		showSocialShare: true,
		showAd: true,
		additionalDataTransformer,
		...options,
	});

export default getAllPageComponent;
